@import "~core/components/badges";
.badge-product-container {
    margin: 0;
    height: 22px;
    margin-top: 8px;
    .offset-slider & {
        margin-top: $spacer;
    }

    @include media-breakpoint-up(md) {
        margin-left: 20px;
        height: 30px;
    }

    .badge-product {
        text-transform: none;
        font-size: 10px;
        font-weight: 600;
        background: $copper;
        color: $cream;
        height: 22px;

        &.new-badge {
            background: $dark-green;
        }

        @include media-breakpoint-up(md) {
            font-size: $product-tile-pdp-badge-font-size;
            height: 30px;
        }
    }

    &.badge-image-over {
        position: absolute;
        flex-direction: column;
    }

}
.badge-product {
    text-transform: capitalize;
    letter-spacing: normal;
}

.pdp-badge-product-container {
    margin-bottom: $spacer;
    .badge-product {
        font-family: $primary-font;
        background: $brand-secondary;
        color: $cream;
        padding: $product-tile-pdp-badge-padding;
        font-size: $product-tile-pdp-badge-font-size;
        font-weight: $product-tile-pdp-badge-font-weight;
        margin-right: $spacer;
        opacity: 0.9;
    }
}

.hide-badge { // hide fragrance tag on PDP detail recommender
    .fragrance-badge {
        display: none;
    }
    .product {
        .product-tile.on-hover {
            border-right: none;
            max-height: 300px;
            @include media-breakpoint-up(sm) {
                max-height: 100%;
            }
        }
    }
}
