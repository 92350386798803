//CARD LIST
.product-list-enhancements-card-list {
    background-color: $white;

    .card-header {
        background-color: $wishlist-area-card-header-background;
        border-bottom: $wishlist-area-card-border-size solid $wishlist-area-card-border-color;
        padding: $spacer $spacer*1.25;
    }

    &-header {
        &-link {
            text-decoration: none;
        }

        &-edit,
        &-delete {
            text-decoration: $wishlist-area-header-link-text-decoration-hover;

            &:hover,
            &:focus {
                color: $wishlist-area-header-link-color-hover;
            }
        }
    }

    &-name {
        text-transform: capitalize;
    }

    &-body {
        display: flex;
        gap: $spacer;
        flex-wrap: wrap;
        .card.product-list-enhancements-card-list & {
            padding: $spacer*1.25;
        }

        &-empty {
            gap: 0;

            &-blank {
            }

            &-message {
                text-align: center;
            }
        }

        &-image,
        &-image-blank,
        .extra-items-block {
            flex: 0 1 auto;
            width: 100%;
            max-width: calc((100% / 4) - ((#{$card-body-padding-right + $card-body-padding-left + $spacer}) /  4));
        }
        &-image {
            height: auto;
        }
    }

    &.is-public{
        @include icon($wishlist-public-icon + " PUBLIC", after, 10px, $wishlist-public-icon-color);
        overflow: hidden;

        &:after {
            letter-spacing: 3px;
            font-weight: bold;
            font: bold 10px 'icomoon', helvetica, sans-serif;
            background-color: $wishlist-public-background-color;
            padding: $spacer-sm $spacer-sm*2 calc(#{$spacer-sm} - 1px) $spacer-sm*2+3;
            position: absolute;
            bottom: 0;
            right: 0;
            border-radius: 5px 0 $border-radius 0;
            margin-right: -65px;
            transition: margin $transition-speed ease-in-out;
        }

        &:hover {
            &:after {
                margin-right: 0;
                font: bold  10px 'icomoon', helvetica, sans-serif;
            }
        }
    }
}

//CARD PRODUCT
.product-list-enhancements-card-product {
    display: flex;
    flex-flow: column;
    height: 100%;
    overflow: hidden;
    position: relative;
    padding: $spacer;
    &-toggle {
        &:after {
            position: absolute;
            content: '';
            display: flex;
            height: $radiocheck-button-size;
            width: $radiocheck-button-size;
            background-color: $radiocheck-background-color;
            border: 1px solid $radiocheck-border-color;
            border-radius: $border-radius;
            top: 10px;
            right: 10px;
            justify-content: center;
            align-items: center;
        }
        &.selected {
            @include icon($icon-checkmark, after, 10px, $radiocheck-icon-color);
            &:after {
                background-color: $radiocheck-background-color-selected;
                border-color: $radiocheck-border-color-selected;
                display: flex;
            }
        }
    }
    &-quantity {
        text-align: center;
    }
    &-image {
        width: 100%;
        margin-bottom: $spacer;
    }
    &-add,
    &-remove {
        width: 100%;
    }
    &-form {
        &-quantity {
            position: relative;
            margin-bottom: $spacer;
        }
    }

    .product-tile {
        .quick-action-btns {
            bottom: unset;
            margin-top: calc( 0px - #{$button-secondary-padding-top} - #{$button-line-height} - #{$button-secondary-padding-bottom} - #{$spacer} );
        }
    }

    .price {
        flex-grow: 1;

        .price-del {
            order: 2;
        }

        span {
            del ~ .sales {
                padding-right: 0;
            }
        }
    }
}
