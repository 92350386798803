@import "~core/mixins";

@mixin button($style: "", $customBackground: "", $customText: "", $customBorder: "", $size: "") {
    display: inline-block;
    position: relative;
    cursor: pointer;
    user-select: none;
    border-width: $button-border-width;
    border-style: solid;
    font-family: $button-font;
    text-transform: $button-text-transform;
    transition: all $transition-speed ease-out;
    text-decoration: none;
    border-radius: $button-border-radius;
    text-align: $button-text-align;
    font-weight: $button-font-weight;
    @if $size == small {
        font-size: $button-sm-font-size;
        line-height: $button-sm-line-height;
        padding: $button-sm-padding-top $button-sm-padding-right $button-sm-padding-bottom $button-sm-padding-left;
        letter-spacing: $button-sm-letter-spacing;
    } @else {
        font-size: $button-font-size;
        letter-spacing: $button-letter-spacing;
        line-height: $button-line-height;
        padding: $button-padding-top $button-padding-right $button-padding-bottom $button-padding-left;
    }
    @if $style == primary {
        font-family: $button-primary-font;
        text-transform: $button-primary-text-transform;
        color: $button-primary-color;
        background-color: $button-primary-background;
        border-width: $button-primary-border-width;
        border-radius: $button-primary-border-radius;
        border-color: $button-primary-border-color;
        text-decoration: $button-primary-text-decoration;
        text-align: $button-primary-text-align;
        font-weight: $button-primary-font-weight;

        outline: 2px $button-primary-background solid;

        @if $size == small {
            padding: $button-sm-primary-padding-top $button-sm-primary-padding-right $button-sm-primary-padding-bottom $button-sm-primary-padding-left;
        } @else {
            padding: $button-primary-padding-top $button-primary-padding-right $button-primary-padding-bottom $button-primary-padding-left;
        }
        &:hover,
        &:focus,
        &[aria-describedby*="popover"] {
            color: $button-primary-color-hover;
            background-color: $button-primary-background-hover;
            border-color: $button-primary-border-color-hover;
            text-decoration: $button-primary-text-decoration-hover;
            outline-color: $button-primary-background-hover;
        }
        .dark-theme & {
            color: $button-primary-dark-theme-color;
            &:hover {
               color:  $button-primary-dark-theme-color-hover;
            }
        }
    }
    @if $style == secondary {
        font-family: $button-secondary-font;
        text-transform: $button-secondary-text-transform;
        color: $button-secondary-color;
        border-width: $button-secondary-border-width;
        border-radius: $button-secondary-border-radius;
        background-color: $button-secondary-background;
        border-color: $button-secondary-border-color;
        text-decoration: $button-secondary-text-decoration;
        text-align: $button-secondary-text-align;
        font-weight: $button-secondary-font-weight;

        outline: 2px $button-secondary-background solid;

        @if $size == small {
            padding: $button-sm-secondary-padding-top $button-sm-secondary-padding-right $button-sm-secondary-padding-bottom $button-sm-secondary-padding-left;
        } @else {
            padding: $button-secondary-padding-top $button-secondary-padding-right $button-secondary-padding-bottom $button-secondary-padding-left;
        }
        &:hover,
        &:focus,
        &[aria-describedby*="popover"] {
            color: $button-secondary-color-hover;
            background-color: $button-secondary-background-hover;
            border-color: $button-secondary-border-color-hover;
            text-decoration: $button-secondary-text-decoration-hover;
            outline-color:  $button-secondary-background-hover;
        }
        .dark-theme & {
            color: $button-secondary-dark-theme-color;
            &:hover {
                color: $button-secondary-dark-theme-color-hover;
            }
        }
    }
    @if $style == tertiary {
        font-family: $button-tertiary-font;
        text-transform: $button-tertiary-text-transform;
        color: $button-tertiary-color;
        background-color: $button-tertiary-background;
        border-radius: $button-tertiary-border-radius;
        border-width: $button-tertiary-border-width;
        border-color: $button-tertiary-border-color;
        text-decoration: $button-tertiary-text-decoration;
        text-align: $button-tertiary-text-align;
        font-weight: $button-tertiary-font-weight;

        &::after {
            border: 1px solid $button-tertiary-background;
        }

        @if $size == small {
            padding: $button-sm-tertiary-padding-top $button-sm-tertiary-padding-right $button-sm-tertiary-padding-bottom $button-sm-tertiary-padding-left;
        } @else {
            padding: $button-tertiary-padding-top $button-tertiary-padding-right $button-tertiary-padding-bottom $button-tertiary-padding-left;
        }
        &:hover,
        &:focus,
        &[aria-describedby*="popover"] {
            color: $button-tertiary-color-hover;
            background-color: $button-tertiary-background-hover;
            border-color: $button-tertiary-border-color-hover;
            text-decoration: $button-tertiary-text-decoration-hover;
        }
        .dark-theme & {
            color: $button-tertiary-dark-theme-color;
            border-color: $button-tertiary-dark-theme-color;
            &:hover {
                color: $button-tertiary-dark-theme-color-hover;
            }
        }
    }
    @if $style == custom {
        background: $customBackground;
        color: $customText;
        border-color: $customBorder;
        &:hover,
        &:focus,
        &[aria-describedby*="popover"] {
            background: darken($customBackground, 5%);
            border-color: darken($customBorder, 5%);
        }
    }

    // If icon class is applied directly to button
    &[class*="icon-"],
    &[class^="icon-"] {
        padding-left: calc($button-padding-left * 2);
        @if $size == small {
            padding-left: calc($button-sm-padding-left * 2);
            &:before {
                @include button-icon-styles(before, $button-sm-icon-size, inherit);
            }
        } @else {
            &:before {
                @include button-icon-styles(before, $button-icon-size, inherit);
            }
        }
    }

    // Disabled
    &:disabled {
        opacity: 1;
        background-color: $button-disabled-background;
        color: $button-disabled-color;
        border-color: $button-disabled-border-color;
        outline-color: $button-disabled-background;
    }
}

// This mixin should only be called from within _mixins.scss
@mixin button-icon-styles($position, $size, $color) {
    @include icon-styles($size, $color);
    position: absolute;

    @if $position == after {
        justify-content: flex-end;
        $afterIconButtonOffset: 8px;
        padding-right: $afterIconButtonOffset;
    }
    @if $position == before {
        $beforeIconButtonOffset: $size + 8px;
        transform: translateX(-$beforeIconButtonOffset);
    }
}
