// Product List Enhancements
@import "wishlist/helpers";
@import "wishlist/card";
@import "wishlist/modal";
@import "wishlist/overlay";
@import "wishlist/toast";
@import "wishlist/landing";
@import "wishlist/detail";


//Deprecated wishlist css leaving for now
.add-to-wishlist-messages {
    @include toast-message();
}
.wishlist {
    white-space: nowrap;
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
    &:not(.move) {
        position: absolute;
        top: 5px;
        right: 5px;
        border: none;
        z-index: $z-index-wishlist-icon;
        @include media-breakpoint-up(sm) {
            top: 10px;
            right: 10px;
        }
    }
    .wishlist-icon {
        width: $product-tile-wishlist-link-size;
        height: $product-tile-wishlist-link-size;
        line-height: $product-tile-wishlist-link-size;
        background-color: $product-tile-wishlist-link-background-color;
        box-shadow: $product-tile-wishlist-link-box-shadow;
        border-radius: $product-tile-wishlist-link-border-radius;
        @include icon($product-tile-wishlist-link-icon, before, $product-tile-wishlist-link-icon-size, $product-tile-wishlist-link-color);
        &.selected {
            @include icon($product-tile-wishlist-link-icon-selected, before, $product-tile-wishlist-link-icon-size, $product-tile-wishlist-link-color);
        }
        &.before {
            &::before {
                @include absolute-center;
                padding-top: 1px;
                padding-left: 1px;
            }
        }
    }
}
.wishlistItemCards {
    .select-attributes-btn {
        white-space: nowrap;
    }
    .line-item-availability {
        .line-item-attributes {
          white-space: normal;
        }
    }
}
.btn-update-wishlist-product {
    width: 100%;
}
.wishlist-remove-btn {
    @include delete-card-button;
    margin: $card-wishlist-remove-btn-margin-top $card-wishlist-remove-btn-margin-right $card-wishlist-remove-btn-margin-bottom $card-wishlist-remove-btn-margin-left;
    @include media-breakpoint-up(xl) {
        margin-left: 28px;
    }
    left: 0;
}
