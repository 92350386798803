.product-list-enhancements-detail {
    margin-bottom: 60px;
    min-height: 30vh;

    &-nav {
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: center;
        transition-property: all;
        transition-duration: $transition-speed;
        transition-timing-function: ease-in;

        .action-btns {
            transition-property: all;
            transition-duration: $transition-speed;
            transition-timing-function: ease-in;
            opacity: 0;
            margin-top: $spacer*2;
        }

        &.open {
            transition-timing-function: ease-in;

            .action-btns {
                opacity: 1;
                transition-timing-function: ease-in;
                margin-top: 0;
            }
        }
    }

    .public-toggle {
        label {
            &:before,
            &:after {
                //alignment fix
                margin-top: -2px;
            }
        }
    }

    &-name {
        background-color: $white;
    }

    &-deselect {
        align-items: center;
        display: flex;
        text-decoration: none;
        transition-property: all;
        transition-duration: $transition-speed;
        transition-timing-function: ease-in;
        &:hover {
            text-decoration: $wishlist-area-header-link-text-decoration-hover;
        }

        &.hide {
            opacity: 0;
            pointer-events: none;
        }

        &.show {
            transition-timing-function: ease-in;
            opacity: 1;
            pointer-events: auto;
        }
    }

    &-share:disabled {
        pointer-events: auto;
        cursor: unset;
    }

    .product-list-enhancements-header {
        &-name {
            text-transform: capitalize;
        }
    }

    .product-tile {
        .quickview {
            &.btn.show {
                opacity: 1;
            }
        }
    }

}

.product-list-enhancements-detail-notes {
    &-form-group {
        overflow: hidden;
    }
    &-text {
        height: $wishlist-details-text-area-height;
        transition-property: all;
        transition-duration: $transition-speed;
        transition-timing-function: ease-in;
        margin-top: -#{$wishlist-details-text-area-height};
        &.open {
            transition-timing-function: ease-in;
            margin-top: 0;
        }
    }
}

