.account-image {
    background-image: url(../../images/account.jpg);
    background-position-y: 40%;
}

#sp-my-subscriptions {
    min-height: 30em;
}

#sp-my-subscriptions .sp-field.custom-select select {
    border: none;
}

.delivery-interval-group #delivery-periods {
    max-width: 50px;
}
