@each $size in map-keys($grid-breakpoints) {

    @include media-breakpoint-down($size) {
        .collapsible-#{$size} {
            border-color: $collapsible-border-color;
            border-width: 0 0 1px 0 !important;
            .card-header {
                padding: 0;
                border-color: $collapsible-border-color;
                border-width: 0 0 1px 0 !important;
            }
            .title {
                @include icon($collapsible-header-icon-expand, after, $collapsible-header-icon-size, $collapsible-header-icon-color);
                background-color: $collapsible-header-background;
                color: $collapsible-header-color;
                padding-top: $card-header-padding-top;
                padding-right: $card-header-padding-right + $collapsible-header-icon-size + 5px;
                padding-bottom: $card-header-padding-bottom;
                padding-left: $card-header-padding-left;
                user-select: none;
                border-width: 0;
                cursor: pointer;
                &::after {
                    position: absolute;
                    top: $card-header-padding-top;
                    right: $card-header-padding-right;
                }
            }
            .content,
            .card-body {
                display: none;
                background-color: $collapsible-body-background;
                color: $collapsible-body-color;
                border-width: 0 0 1px 0 !important;
            }

            &.active {
                .title {
                    @include icon($collapsible-header-icon-close, after, $collapsible-header-icon-size, $collapsible-header-icon-color);
                }
                .content,
                .card-body {
                    display: block;
                }
            }
            &:not(:last-child) {
                margin-bottom: 0;
                border-bottom: none;
                border-radius: 0;
                &+.collapsible-#{$size} {
                    border-radius: 0;
                }
            }
            &:not(.active):not(.aside):not(.no-borders) {
                .card-header {
                    border-bottom: 1px solid $collapsible-border-color;
                }
            }
            &.aside,
            &.no-borders {
                .title::after {
                    right: $card-header-padding-right;
                }
            }
            &.aside .title::after {
                top: $card-aside-header-padding-top;
            }
            &.no-borders .title::after {
                top: $card-header-padding-top;
            }
            button {
                text-align: left;
                display: block;
                width: 100%;
                &:focus {
                    z-index: 1; // keep focus outline visible
                }
            }
        }
    }
}
