@import "~core/components/menu";

.multilevel-dropdown {
    .dropdown-menu {
        @include media-breakpoint-up(lg) {
            left: auto;
        }
    }
}

.main-menu {
    display: flex;
    height: 100%;
    align-items: flex-end;
  }

// Megamenu
.navbar-nav {
    @include media-breakpoint-up(lg) {
        margin-left: -($header-navbar-link-spacing/2);
    }
}
.navbar.bg-inverse .navbar-nav .nav-link {
    @include media-breakpoint-up(lg) {
        padding: 34px 18px;
    }
}
.navbar-nav .megamenu {
    >.dropdown-menu {
        max-height: 70vh;
        overflow-x: hidden;
        box-shadow: $megamenu-box-shadow;
        &:before {
            box-shadow: none;
        }
        @include media-breakpoint-up(xl) {
            max-height: inherit;
            overflow: unset;
            box-shadow: none;
            &:before {
                box-shadow: $megamenu-box-shadow;
            }
        }
        &.show {
            @include media-breakpoint-up(lg) {
                justify-content: flex-start;
            }
        }
        .menu-subcategories {
            .dropdown-menu {
                display: block;
                margin-top: 5px;
                border-top: 1px solid $champagne;
                &.columned {
                    @include media-breakpoint-up(lg) {
                        column-count: 2;
                    }
                }
            }
            .dropdown-item.secondary {
                &.no-children {
                    @include media-breakpoint-up(lg) {
                        padding-bottom: 0;
                    }
                }
                >.dropdown-toggle {
                    @include media-breakpoint-up(lg) {
                        font-size: 18px;
                    }
                }
                .dropdown-link:not(.dropdown-toggle) {
                    text-transform: none;
                    line-height: $megamenu-link-line-height;
                    letter-spacing: $megamenu-link-letter-spacing;
                    @include media-breakpoint-up(lg) {
                        position: relative;
                        font-weight: 400;
                        color: $black;
                        overflow-x: hidden;
                    }
                    &::after {
                        @include media-breakpoint-up(lg) {
                            content: '';
                            display: block;
                            position: absolute;
                            bottom: $spacer / 2;
                            left: -100%;
                            height: 2px;
                            width: calc(100% - #{$spacer-sm});
                            background-color: $brand-secondary;
                            transition: left 200ms ease-out;
                        }
                    }
                    &:hover {
                        @include media-breakpoint-up(lg) {
                            color: $brand-secondary;
                        }
                        &::after {
                            @include media-breakpoint-up(lg) {
                                left: $spacer-sm;
                            }
                        }
                    }
                    &.highlight {
                        font-weight: 700;
                        font-style: italic;
                        color: $brand-secondary;
                    }
                }
            }
        }
        &.has-asset-1,
        &.has-asset-2 {
            .menu-subcategories-container {
                @include media-breakpoint-up(lg) {
                    max-width: 75%;
                    .menu-subcategories {
                        > .dropdown-item {
                            flex-basis: auto;
                        }
                    }
                }
                .menu-subcategories {
                    justify-content: space-between;
                }
            }
        }
        &.has-asset-1.has-asset-2 {
            .menu-subcategories-container {
                @include media-breakpoint-up(lg) {
                    max-width: 50%;
                }
            }
        }
    }
    // Megamenu content assets
    .has-asset-1 .megamenu-asset-1,
    .has-asset-2 .megamenu-asset-2 {
        padding: $megamenu-padding-top-bottom $spacer;
        @include media-breakpoint-down(md) {
            border-top: 1px solid $champagne;
            margin-left: 20px;
            margin-right: 20px;
            padding-left: 0;
            padding-right: 0;
        }

        @include media-breakpoint-up(lg) {
            margin: 0 auto;
            border-left: none;
            border-right: none;
            width: 25%;
        }

        img {
            @include media-breakpoint-up(lg) {
                margin-bottom: -$megamenu-padding-top-bottom;
                margin-top: -$megamenu-padding-top-bottom;
                margin-left: $spacer;
                margin-right: -$spacer;
            }
        }
    }
    .has-asset-1 .megamenu-asset,
    .has-asset-2 .megamenu-asset {
        &:last-of-type {
            @include media-breakpoint-up(lg) {
                margin-right: 24px;
            }
        }
        .category-tile {
            &::before {
                background-image: none;
            }
        }
    }
}

//Mobile menu
.main-menu.menu-toggleable-left {

    @include media-breakpoint-down(md) {
        background-color: $cream;
        width: 100vw;
        max-width: unset;
        .row {
            align-items: baseline;
        }
    }
    .close-menu {
        @include media-breakpoint-down(md) {
            background-color: $cream;
            .back {
                .icon {
                    margin-right: 0;
                }
                text-transform: uppercase;
                font-weight: 600;
            }
        }
    }
    .dropdown-menu {
        background-color: $cream;
        .menu-subcategories {
            .dropdown-item {
                &:first-child {
                    @include media-breakpoint-down(md) {
                        border-top: 1px solid $champagne;
                    }
                }
                .top-category {
                    @include media-breakpoint-down(md) {
                        border-bottom: 1px solid $champagne;
                        margin: 0 20px 20px;
                        width: auto;
                        .nav-link-secondary {
                            padding-left: 0;
                            position: relative;
                            @include icon($icon-arrow-right-thin, 'after');
                            color: $brand-secondary;
                            &:after {
                                position: absolute;
                                right: $spacer;
                            }
                        }
                    }
                }
            }

            .dropdown-item,
            .dropdown-item + .dropdown-item {
                @include media-breakpoint-down(md) {
                    border-top: none;
                }
            }
            .dropdown-link,
            .nav-link {
                letter-spacing: normal;
                @include media-breakpoint-down(md) {
                    &:not([role='menuitem']) {
                        font-family: $secondary-font;
                        font-size: 18px;
                        text-transform: none;
                        font-weight: 400;
                    }
                }
                &.nav-link-tertiary {
                    font-family: $primary-font;
                    text-transform: none;
                    font-size: 14px;
                    padding-top: 0;
                    padding-bottom: 0;
                    line-height: 36px;
                    &:hover {
                        color: $brand-secondary;
                    }
                }
            }
        }
        .top-category {
            ~ .menu-subcategories > .dropdown-item .top-category ~ .dropdown-item {
                margin-left: 20px;
                margin-right: 20px;
            }
            .nav-link-secondary {
                padding-left: 16px;
            }
            >.nav-link,
            >.dropdown-link {
                @include media-breakpoint-down(md) {
                    color: $black;
                    font-size: 16px;
                    font-weight: 600;
                    background-color: $cream;
                }
            }
        }
    }
    .custom-dropdown {
        .dropdown-toggle {
            &:after {
                position: absolute;
                right: $spacer;
            }
        }
    }

    &[aria-hidden="true"] {
        .search-mobile .search-field,
        .fa-search {
            display:none;
        }
        @include media-breakpoint-down(md) {
            .nav-link.dropdown-toggle,
            a,
            button {
                display: none;
            }
        }
    }
}

.custom-menu {
    .container {
        padding: 0;
        > .row {
            align-items: stretch;
        }
    }
    .throw-title-top {
        .image-cropper {
            padding: $spacer 0 0 $spacer*2;
            @include media-breakpoint-up(sm) {
                padding: $spacer*2 0 $spacer*2 $spacer*2;
            }
        }
        .category-tile-text {
            position: absolute;
            top: $spacer/4;
            left: $spacer;
            font-size: 16px;
            color: $brand-primary;
        }
    }
}
.megamenu-asset-1,
.megamenu-asset-2 {
    padding-top: $megamenu-padding-top-bottom;
    padding-bottom: $megamenu-padding-top-bottom;

}

.nav-link-icon {
    position: absolute;
    height: 32px;
    width: 32px;
    margin-left: 12px;
    background-size: contain;
    pointer-events: none;
}

.mobile-nav-account-border{
    display: block;
    width: calc(100% - #{$spacer*2});
    margin: $spacer auto 0;
    border-top: 1px solid $copper;
}

.dropdown-item-badge {
    text-transform: uppercase;
    letter-spacing: 0.01em;
    color: $brand-secondary;
    font-weight: normal;
}
