.product-list-enhancements-toast {
    @include toast-alert;
    @include toast-message;
    padding: $spacer;
    top: $wishlist-toast-distance-from-top;

    // We are putting a bunch of content in this toast alert
    // need to expand it all the way on small screeens to prevent breaking wrap
    @include media-breakpoint-down(sm){
        width: calc(100vw - #{$spacer*2});
    }

    &.alert-dismissible {
        padding-right: 0;
    }

    &:focus {
        outline: $outline-focus-width $outline-focus-style $outline-focus-color;
    }

    &.hide {
        z-index: -1;
        opacity: 0;
        margin-top: calc(#{-($wishlist-toast-distance-from-top)} + #{-($spacer)});
        transition: all $transition-speed;
    }

    &.show {
        z-index: $z-index-toast;
        opacity: 1;
        margin-top: 0px;
    }

    &-content {
        display: flex;
        gap: 10px;
    }

    &-message-wrapper {
        align-items: center;
        display: flex;
        flex: auto;
        .message {
            @include icon($icon-checkmark, before, $wishlist-area-icon-size, $dark-gray);
            color: $dark-gray;
            display: flex;
            flex: auto;
            gap: 5px;
            .undo {
                text-decoration: underline;
                &:hover,
                &:focus {
                    color: $black;
                }
            }
            .manage,
            .undo {
                &:before {
                    content: "|";
                    padding-right: $spacer/2;
                    text-decoration: none;
                    display: inline-block;
                }
            }
        }
    }

    &-dismiss {
        @include icon($icon-close, before, $wishlist-area-icon-size, $dark-gray);
    }
}