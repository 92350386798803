// Wrapper Container Classes to use in conjuction with Page Designer

.header-transpose {
// This is a container class for use with the Page Designer WYSIWYG module in case you need smaller header sizes.
    &-1 {
        h1, .h1 {
            @include header(2);
        }
        h2, .h2 {
            @include header(3);  
        }
        h3, .h3 {
            @include header(4);
        }
        h4, .h4 {
            @include header(5);
        }
    }
    &-2 {
        h1, .h1 {
            @include header(3);
        }
        h2, .h2 {
            @include header(4);  
        }
        h3, .h3 {
            @include header(5);
        }
        h4, .h4 {
            @include header(6);
        }
    }
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4 {
    a { 
        font-weight: inherit;
    }
}

.megamenu-sidebar-links {
    h4 {
        margin-bottom: 28px;
    }
}

.card-link {
    text-transform: uppercase;
    letter-spacing: 0.1em;
    @include icon($icon-arrow-right-thin, 'after', 20px);
    &:after {
        position: relative;
        top: 3px;
        margin-left: 20px;
    }
}