.product-list-enhancements-landing {

    &-pagination {
        text-align: center;
        margin-bottom: $spacer;
    }

    .lists {
        gap: 20px 4px;
        .list-grid-item {
            flex-basis: 100%;
            overflow: hidden;
            @include media-breakpoint-up(md) {
                flex-basis: 33%;
            }
        }
    }

    .list-container {
        min-height: 60vh;

        .notes {
            &.hide {
                opacity: 0;
                max-height: 0;
                transition: all $transition-speed;
                padding-left: $spacer*1.25;
                padding-right: $spacer*1.25;
            }
            &.show {
                opacity: 1;
                max-height: 100%;
                padding-top: $spacer;
            }
        }
    }

    .product-list-enhancements-create-list-btn {
        @include media-breakpoint-up(md) {
            max-width: 25vw;
        }
    }

    .message {
        display: flex;
        text-align: center;
    }
}
// search area on landing
.response {
    .wishlist-search-results {
        .card {
            &:hover {
                background-color: $off-white;
                .card-header {
                    background-color: $off-white;
                }
            }
        }
        .view-wishlist {
            cursor: pointer;
        }
    }
}
