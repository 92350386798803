@import "~core/components/modal";

.modal {

    .modal-header {
        font-family: $secondary-font;
        .modal-title {
            font-family: $secondary-font;
        }
    }
}
