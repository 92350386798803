@import "~core/helperClasses";

.mix-darken {
    mix-blend-mode: darken;
}

.btn-link {
    border-width: 0;
}

.btn[data-toggle='collapse'] {
    @include icon($icon-collapse, after, 14px);
    &.collapsed,
    &[aria-expanded='false'] {
        @include icon($icon-expand, after, 14px);
    }
}

.leading-lines{
    .start-lines,
    .end-lines{
        span{
            background-color: transparent;
        }
    }
}
.bg-size {
    &--contain {
        background-size: contain !important;
        background-position: top left !important;
        .hero-media,
        .column-background {
            background-size: contain !important;
            background-position: top left !important;
        }
    }
    &--cover {
        background-size: cover !important;
        .hero-media,
        .column-background {
            background-size: cover !important;
        }
    }
}

.text-shadow {
    text-shadow: 0 0 0.5em $black;
    &--light {
        text-shadow: 0 0 0.5em $white;
    }
}

.modal {
    .quick-view-dialog{
        .modal-body,
        .modal-header {
            background-color: $brand-tertiary;
        }
    }
}

.fragrance-badge {
    position: absolute;
    right: 0;
    top: 0;
    margin-right: 32px;

    .fragrance-img {
        height: 20px;
        width: 20px;
    }
}
