@import "~core/components/slider";

.slider-container {
  &.light {
      .tns-controls button {
          background-color: transparent;

          &:before {
            color: $midnight-black;
          }

          &:hover {
            &:before {
                color: $midnight-black;
              }
          }
      }
  }
}
