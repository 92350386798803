.alert {
    font-weight: 600;
    letter-spacing: normal;
    padding: $alert-padding;
    .close {
        opacity: 1 !important;
        color: $black;
        font-weight: normal;
        padding: $alert-padding;
    }
}
