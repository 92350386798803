@import "~core/components/hero";

.hero-layered {
    display: block;
    &.no-padding {
        .hero-container {
            padding: 0;
        }
    }
    .hero-container {
        position: absolute;
        &:not(.hero-container--background-layer) {
            @include media-breakpoint-down(md) {
                position:relative;
            }
        }
        &--mobile-above {
            @include media-breakpoint-down(md) {
                bottom: -($spacer * 5);
                margin-top: -($spacer * 5);
            }
        }
        &--mobile-below {
            @include media-breakpoint-down(md) {
                top: -($spacer * 5);
            }
        }
    }
    .hero-bottom-layer {
        z-index: 10;
        @include media-breakpoint-down(md) {
            position:relative;
        }
    }
    .hero-text-layer {
        z-index: 20;
        @include media-breakpoint-down(md) {
            position:relative;
        }
    }
    .hero-top-layer {
        z-index: 30;
        @include media-breakpoint-down(md) {
            position:relative;
        }
    }
    .hero-adornment {
        position: absolute;
        &.top-left {
            top: 0;
            left: 0;
        }
        &.top-center {
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }
        &.top-right {
            top: 0;
            right: 0;
        }
        &.middle-left {
            top: 50%;
            transform: translateY(-50%);
            left: 0;
        }
        &.middle-right {
            top: 50%;
            transform: translateY(-50%);
            right: 0;
        }
        &.bottom-left {
            bottom: 0;
            left: 0;
        }
        &.bottom-center {
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }
        &.bottom-right {
            bottom: 0;
            right: 0;
        }
        &.layer-back {
            z-index: 1;
        }
        &.layer-behind {
            z-index: 11;
        }
        &.layer-above {
            z-index: 21;
        }
        &.layer-front {
            z-index: 31;
        }
    }
    .card-adornment {
        &.overlap-top {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
            width: 100%;
        }
    }
}


.hero-text {
    z-index: 99;
    .hero-subtitle {
        &:not([class*='p--']):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.b1):not(.b2):not(.b3):not(.b4):not(.custom) {
            font-family: $hero-subtitle-font;
            font-size: $hero-subtitle-font-size;
            color: $hero-subtitle-font-color;
            text-transform: $hero-subtitle-text-transform;
            line-height: $hero-subtitle-line-height;
        }
    }
}

.top-hero {
    .hero-container {
        padding-top: $header-height !important;
        padding-bottom: $spacer;
        @include media-breakpoint-up(md) {
            padding-top: $header-height-md !important;
        }
        @include media-breakpoint-up(lg) {
            padding-top: $header-height-lg !important;
        }

        &.hero-container--adornment-layer {
            @include media-breakpoint-down(md) {
                padding: 0 !important;
            }
        }
    }
    &.short {
        min-height: $hero-min-height-short + $header-height;
        @include media-breakpoint-up(md) {
            min-height: $hero-min-height-short-md + $header-height-md;
        }
        @include media-breakpoint-up(lg) {
            min-height: $hero-min-height-short-lg + $header-height-lg;
        }
    }
    &.medium {
        min-height: $hero-min-height-medium + $header-height;;
        @include media-breakpoint-up(md) {
            min-height: $hero-min-height-medium-md + $header-height-md;;
        }
        @include media-breakpoint-up(lg) {
            min-height: $hero-min-height-medium-lg + $header-height-lg;;
        }
    }
    &.tall {
        min-height: $hero-min-height-tall + $header-height;;
        @include media-breakpoint-up(md) {
            min-height: $hero-min-height-tall-md + $header-height-md;;
        }
        @include media-breakpoint-up(lg) {
            min-height: $hero-min-height-tall-lg + $header-height-lg;;
        }
    }
    &:after {
        content:'';
        display:block;
        width: 100%;
        height:430px;
        position: absolute;
        left: 0;
        bottom: 0;
        background:linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, $cream 100%);
        mix-blend-mode:normal;
    }
}

.outline {
    outline: 1px solid $white;
    outline-offset: 8px;
    &-inset {
        &:after {
            top:0;
            right: 0;
            bottom: 0;
            left: 0;
            width: auto;
            border:1px solid $white;
            content: '';
            height: auto;
            margin: 6px;
            position: absolute;
            pointer-events: none;
        }
    }
}

.arched {
    border-radius: 24em 24em 0 0;
    &:after {
        top:0;
        right: 0;
        bottom: 0;
        left: 0;
        width: auto;
        border:1px solid $white;
        content: '';
        height: auto;
        margin: 6px;
        border-radius: 24em 24em 0 0;
        position: absolute;
        pointer-events: none;
    }
}

.card-shadow {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
    top: -8px; // adjust for shadow getting chopped off by overflow hidden when text has bottom alignment
}

.hero-product {
	.product-tile {
		.image-container {
			max-width: 200px;
			margin: 0 auto 10px;
		}
	}
	.badge-product-container,
	.fragrance-badge {
		display: none;
	}
	.price {
		margin: $spacer 0;
	}
}

.resize-hero {
    .hero {
        justify-content: center;
        .hero-container {
            width: 85%;
            @include media-breakpoint-up(sm) {
                width: 100%;
            }
        }
    }
}

.full-bleed {
    .not-full-bleed {
        .hero-media {
            width: 100%;
        }
    }
}
