// MODAL DELETE
.product-list-enhancements-modal-delete-list,
.product-list-enhancements-modal-delete-product,
.product-list-enhancements-modal-delete-products {
    .modal-footer {
        > * {
            flex: auto;
        }
    }
}

//MODAL EDIT
.product-list-enhancements-edit-list-form {
    .modal-footer {
        display: flex;
        flex-direction: column;
        gap: $spacer;
        padding-top: $spacer*2;
        > .btn {
            width: 100%;
            + .btn {
                margin-left: 0;
            }
        }
    }
}

//MODAL SHARE
.product-list-enhancements-modal-share-list {
    .form-group {
        margin-top: 0;

        .form-control {
            border-right: 0px;
        }
    }
}


//MODAL SHOW
.product-list-enhancements-modal-show-lists {
    z-index: $z-index-modal;

    .product-list-enhancements-create-list-btn {
        margin-bottom: $spacer;
    }

    .modal-body {
        padding: 0;
    }

    &-header {
        position: relative;
        display: flex;

        &-close {
            @include icon($wishlist-modal-header-icon-close, after, $wishlist-area-icon-size, $wishlist-modal-header-icon-color);
        }
        &-back {
            @include icon($wishlist-modal-header-icon-back, after, $wishlist-area-icon-size, $wishlist-modal-header-icon-color);
        }
        &-close,
        &-back {
            transition: all $transition-speed;
            &.hide {
                opacity: 0;
                z-index: -1;
            }
            &.show {
                opacity: 1;
                z-index: 0;
            }
        }

        &-title {
            flex-grow: 1;
            text-align: center;
        }
    }

    &-keep {
        margin-bottom: $spacer;
        padding: 0;
    }

    &-toggle {
        padding: 0;

        &-list-form {
            padding-top: $spacer*2;
        }

        &-lists {
            &-options {
                display: flex;
                flex-direction: column;
                padding: $spacer*2 0;
            }

            .move,
            .toggle {
                min-height: calc(#{$spacer + $wishlist-modal-list-image-size});
                display: flex;
                text-transform: none;
                font-weight: 400;
                align-items: center;
                margin-bottom: $spacer;

                &:after {
                    margin-left: auto;
                }

                &.selected {
                    border-color: $light-gray;

                    div {
                        color: $gray;
                    }
                }

                img {
                    width: $wishlist-modal-list-image-size;
                    height: $wishlist-modal-list-image-size;
                    margin-right: $spacer;
                }

                div {
                    display: flex;
                    flex-direction: column;
                    text-align: left;

                    small {
                        color: $gray;
                        font-size: $paragraph-font-size-small;
                    }
                }
            }
        }
    }
}
