// scss-docs-start form-floating-variables
$form-floating-height:            add(3.5rem, $input-height-border) !default;
$form-floating-padding-x:         $input-padding-x !default;
$form-floating-padding-y:         1rem !default;
$form-floating-input-padding-t:   1.625rem !default;
$form-floating-input-padding-b:   .625rem !default;
$form-floating-label-opacity:     .65 !default;
$form-floating-label-transform:   scale(.85) translateY(-.5rem) translateX(.15rem) !default;
$form-floating-transition:        opacity .1s ease-in-out, transform .1s ease-in-out !default;
// scss-docs-end form-floating-variables


.form-floating {
    position: relative;
    margin-bottom: $spacer;
    > .form-control,
    > .form-select {
      height: $input-height;
      padding: $input-padding-top $input-padding-right $input-padding-bottom $input-padding-left;
    }
  
    > label {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%; // allow textareas
      padding: $input-padding-top $input-padding-right $input-padding-bottom $input-padding-left;
      pointer-events: none;
      border: $input-border-width solid transparent; // Required for aligning label's text with the input as it affects inner box model
      transform-origin: 0 0;
      font-size: $form-label-font-size;
      @include transition($form-floating-transition);
    }
  
    // stylelint-disable no-duplicate-selectors
    > .form-control {
      &::placeholder {
        color: transparent;
      }
  
      &:focus,
      &:not(:placeholder-shown) {
        padding-top: $input-padding-top;
        padding-bottom: $input-padding-bottom;
      }
      // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
      &:-webkit-autofill {
        padding-top: $input-padding-top;
        padding-bottom: $input-padding-bottom;
      }
    }
  
    > .form-select {
      padding-top: $input-padding-top;
      padding-bottom: $input-padding-bottom;
    }
  
    > .form-control:focus,
    > .form-control:not(:placeholder-shown),
    > .form-select {
      ~ label {
        transform: $form-floating-label-transform;
        font-weight: $form-label-active-font-weight;
        font-size: $form-label-active-font-size;
      }
    }
    // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
    > .form-control:-webkit-autofill {
      ~ label {
        transform: $form-floating-label-transform;
        font-weight: $form-label-active-font-weight;
        font-size: $form-label-active-font-size;
      }
    }
    // stylelint-enable no-duplicate-selectors
  }
  