@import "~core/components/header";
.header-column-1 {
    position: static;
}
.header-nav {
  border-bottom: none;
}
.header-account-container {
  display: block;

  .header-account-mobile {
    @include media-breakpoint-up(lg) {
      display: none;
    }

    a {
      @include icon($header-navbar-account-icon, before, $header-navbar-account-icon-size, $header-link-color);
    }
  }

  .user {
    .dropdown-toggle {
      display: none;
      @include media-breakpoint-up(lg) {
        display: flex;
    }
    margin: 0;

      &:before {
        margin-right: $spacer-sm;
      }
      &:after {
        margin-left: $spacer-sm;
      }
    }
  }
}

.header .minicart .minicart-quantity {
  top: 50%;
  right: 50%;
  transform: translate(50%, -30%);
}

.header {
    .brand {
        display: flex;
        align-items: center;
    }

    .minicart .minicart-quantity {
        top: 50%;
        right: 50%;
        transform: translate(50%, -30%);
    }
}
