@import "~core/components/productCard";

.product-info {
    .line-item-availability {
        .line-item-attributes {
            &:first-child:after {
                content: '';
            }
        }
    }
}
