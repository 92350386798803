.btn-cta-arrow {
    @include button(custom, transparent, $brand-primary, transparent);
    font-family: $secondary-font;
    text-transform: none;
    letter-spacing: normal;
    font-size: 24px;
    padding-left: 0;
    @include icon($icon-arrow-right-thin, 'after');
    &:hover {
        text-decoration: none;
        color: $brand-secondary;
    }
    &:after {
       position: relative;
       top: 4px;
    }
}