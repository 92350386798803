@import "~integrations/yotpo/yotpo";
//Ratings - PDP top,
.yotpo {
    .yotpo-logo-link-new{
        .yotpo-logo-title {
            color: $black !important;
        }
        .yotpo-icon {
            color: $black !important;
        }
    }
    .yotpo-icon {
        margin-right: 7px !important;
        &.yotpo-icon-star,
        &.yotpo-icon-half-star,
        &.yotpo-icon-empty-star {
            &::before {
                font-family: $icomoon-font-family !important;
            }
        }
        &.yotpo-icon-star {
            &::before {
                content: $icon-star;
            }
        }
        &.yotpo-icon-half-star {
            &::before {
                content: $icon-star-half;
            }
        }
        &.yotpo-icon-empty-star {
            &::before {
                content: $icon-star-empty;
            }
        }
    }
    .yotpo-nav {
        .yotpo-nav-tab {
            position: relative;
            font-family: $primary-font;
            font-weight: 700;
            padding-left: 0 !important;
            padding-right: 0 !important;
            margin-left: $spacer / 2 !important;
            margin-right: $spacer / 2 !important;
            border: none !important;

            .yotpo-nav-wrapper,
            .yotpo-nav-wrapper > span {
                color: $black !important;
                border-bottom: none !important;
                @include header(5);
                text-transform: lowercase !important;
            }

            &.yotpo-active {
                border-bottom: 2px solid $copper !important;
                .yotpo-nav-wrapper,
                .yotpo-nav-wrapper > span {
                    color: $copper !important;
                }
            }
        }
    }
    &.yotpo-main-widget {
        margin: 32px 0 !important;

        @include media-breakpoint-up(md) {
            margin: 60px 0 !important;
        }
        .yotpo-label-container {
            border-top-color: $light-gray;
            .yotpo-logo-link-new {
                display: none;
            }
        }
        .main-widget {
            .yotpo-bottomline.bottom-line-items-container {
                .bottom-line-items {
                    display: flex;
                    flex-flow: row wrap;
                    position: relative;
                    justify-content: center;
                    > * {
                        order: 2;
                    }
                    .avg-score {
                        @include header(3);
                        order: 1;
                    }
                    .reviews-qa-labels-container {
                        order: 2;
                        height: auto;
                        line-height: 1;
                        align-self: center;
                        @include media-breakpoint-down(sm) {
                            order: 3;
                        }
                        .reviews-qa-label {
                            @include header(6);
                            color: $brand-primary !important;
                            font-family: $primary-font;
                        }
                    }
                }
            }
            .write-question-review-buttons-container {
                width: 100%;
                text-align: center;
                float: none;
                button {
                    font-weight: 600;
                    padding-left: 3 * $spacer !important;
                    padding-right: 3 * $spacer !important;
                    .yotpo-icon {
                        display: none;
                    }
                }
                @include media-breakpoint-down(sm) {
                    display: flex;
                    flex-flow: row wrap;

                    button {
                        margin-left: 0 !important;
                        margin-right: 0 !important;
                        margin-bottom: $spacer / 2 !important;
                        margin-top: $spacer / 2 !important;
                        width: 100%;
                    }
                }
                .write-question-button {
                    background-color: $eggshell !important;
                    outline: 2px $eggshell solid !important;
                    span {
                        color: $midnight-black !important;
                    }
                }
            }
            .yotpo-review,
            .yotpo-question {
                .yotpo-icon-profile {
                    background-color: transparent !important;
                    background-image: url('../images/glyphs/circle.svg') !important;
                    background-size: contain;
                    background-repeat: no-repeat;
                    border-radius: 0 !important;
                }

                .yotpo-review-date {
                    font-family: $secondary-font !important;
                    color: $midnight-black !important;
                    font-size: $review-date-font-size !important;
                }
            }

            .yotpo-review {
                .yotpo-main {
                    @include media-breakpoint-up(md) {
                        max-width: 80%;
                    }
                    .content-title, .content-review {
                        color: $brand-primary;
                    }
                }

                .yotpo-footer, .yotpo-main {
                    margin-left: 0 !important;
                }

                .yotpo-icon-profile {
                    display: none;
                }
            }

            .yotpo-reviews-filters {
                border-bottom: none;

                .filters-container {
                    display: flex;
                    flex-flow: row;
                    justify-content: space-between;
                    align-items: center;

                    @include media-breakpoint-down(md) {
                        flex-wrap: wrap;
                    }

                    .free-text-search {
                        flex: 0 0 30%;
                        max-width: 30%;;
                        @include media-breakpoint-down(md) {
                            order: 1;
                            margin-bottom: $spacer;
                            width: 100%;
                            max-width: 100%;
                            flex: 0 0 100%;
                            .free-text-search-input {
                                width: 100%;
                            }
                        }
                        .free-text-search-input {
                            border: none;
                            text-align: center;
                            background-color: $eggshell;
                            max-width: 100%;
                            width: 100%;
                            text-transform: uppercase;
                            &::placeholder {
                                color: $deep-gray;
                                opacity: 1;
                                font-weight: 600;
                            }
                        }
                    }

                    .mobile-more-filters-container {
                        order: 2;
                        .more-filters-btn {
                            margin: 0 !important;
                            .more-filters-icon {
                                display: none;
                            }
                        }
                    }

                    .filters-container-title {
                        flex: 0 0 60%;
                        max-width: 60%;
                        padding-bottom: 0;
                        @include media-breakpoint-down(md) {
                            order: 2;
                            flex: 0 0 50%;
                            max-width: 50%;
                        }
                    }

                    .yotpo-hidden-mobile {
                        flex: 0 0 10%;
                        max-width: 10%;
                        @include media-breakpoint-down(md) {
                            order: 2;
                            flex: 0 0 50%;
                            max-width: 50%;
                        }

                        text-align: right;
                        .filters-dropdown {
                            margin-top: 0px !important;
                            margin-right: 0px !important;

                            .yotpo-dropdown-button {
                                background-color: $cream;
                                border: none;
                                max-width: 100%;
                                width: auto;
                            }
                        }
                    }
                }
            }


        }
    }
    &#yotpo-testimonials {
        .yotpo-bottomline {
            .text-m {
                color: $black !important;
            }
        }
        .yotpo-nav {
            border-bottom: 1px solid $black;

            .yotpo-nav-tab.yotpo-active {
                &::after {
                    bottom: -8px;
                }
            }
        }
        .yotpo-review {
            .yotpo-icon-profile {
                background-color: transparent !important;
                background-image: url('../images/glyphs/circle.svg') !important;
                background-size: contain;
                background-repeat: no-repeat;
                border-radius: 0 !important;
            }
            .yotpo-user-name {
                color: $black;
            }
            .yotpo-review-date {
                font-family: $secondary-font !important;
                color: $midnight-black !important;
                font-size: $review-date-font-size !important;
            }
        }
    }
    .yotpo-empty-search-results {
        .empty-search-results-text,
        .empty-search-results-clear-all-btn {
            width: auto;
        }
    }
}

.page {
    .yotpo-pictures-widget {
        .yotpo-pictures-gallery-title-wrapper {
            text-align: center !important;
            .yotpo-pictures-gallery-title {
                font-family: $tertiary-font !important; // there's an important in the widget code that's coming from yotpo that we need to override here
                color: $brand-primary;
                font-size: 46px;
                line-height: normal;
            }
        }
        .yotpo-slider-title-wrapper {
            text-align: center;
            .yotpo-slider-title {
                font-family: $tertiary-font !important; // there's an important in the widget code that's coming from yotpo that we need to override here
                color: $brand-primary;
                font-size: 46px;
                text-align: center;
            }
        }
        &.yotpo-size-1,
        &.yotpo-size-2,
        &.yotpo-size-3,
        &.yotpo-size-4,
        &.yotpo-size-5 {
            margin-top: 100px;
            .yotpo-slider-title-wrapper {
                .yotpo-slider-title {
                    font-size: 46px;
                }
            }
        }
        .yotpo-hover-enable {
            .yotpo-single-image-container {
                .y-media-hover {
                    .y-hover-elements {
                        .yotpo-hover-cta {
                            @include button(primary);
                            display: block;
                            pointer-events: auto;
                            transition: all 0s ease, border-color $transition-speed ease-out;
                        }
                    }
                }
            }
        }
    }
}

.yotpo-no-reviews .write-question-button {
    display: none !important;
}

.experience-commerce_assets-yotpoRating {
    .yotpo {
        .rating-star {
            margin-right: 5px;
        }
        .text-m {
            display: none;
        }
    }
}
