@import "~core/checkout/checkout";

.bundled-line-item-price {
    padding-right: $spacer-sm;
    text-align: right;

    .line-item-price-info {
        @include label-styles;
        color: $product-card-attribute-color;
    }
}

.order-product-summary {
    .bundled-line-item {
        .bundled-line-item-price {
            padding-right: $spacer-sm;
            text-align: right;

            .line-item-price-info {
                @include label-styles;
                color: $product-card-attribute-color;
            }
        }

        .item-image {
            padding-left: 8px;
            padding-right: 0px;
            width: initial;

            @include media-breakpoint-up(lg) {
                padding-left: 12px;
                padding-right: 4px;
            }

            .product-image {
                max-width: initial;
            }
        }

        &+.bundled-line-item {
            margin-top: $spacer - $spacer/2;
            padding-top: $spacer;
        }
    }
}

.nav-tabs {
    .nav-link {
        @include media-breakpoint-down(sm) {
            font-size: 20px;
        }
    }
}

.shipping-address-block {
    &.is-invalid {
        select:required, input:required:not(.shippingPhoneNumber) {
            border-color: $danger;
        }
    }
}

#listrak-checkout-subscribe {
    gap: 10px;

    @include media-breakpoint-down(xs) {
        flex-direction: column;
    }

    .input-group {
        flex-direction: column;
        flex: 1 1 0;
        height: 50%;
        .form-control {
            width: 100%;
            &.is-invalid {
                border: 1px solid $danger;
            }
        }
        .error {
            font-size: 10px;
            color: $danger;
        }
    }
}
