@import "~core/product/detail";
.product-detail {
    .quantity-stepper {
        height: 44px;
    }
    .ratings {
        margin-left: 0;
       /* .fa-star {
            @include icon($icon-star, before, $pdp-rating-star-size, $product-rating-star-color);
        }
        .fa-star-half-o {
            @include icon($icon-star-half, before, $pdp-rating-star-size, $product-rating-star-color);
        }
        .fa-star-o {
            @include icon($icon-star-empty, before, $pdp-rating-star-size, $product-rating-star-color);
        } */
    }
    .promotions {
        .promotion {
            color: $brand-secondary;
        }
    }
    .qty-cart-container {
        .btn-soldout {
            display: none;
            width: auto;
        }

        .add-to-cart,
        .quantity {
            display: block;
        }
        .error-soldout {
            display: none;
            position: absolute;
            left: 0;
            bottom: -16px;
            font-size: 12px;
            color: $red;
        }
        &.soldout {
            .btn-soldout {
                display: block;
            }

            .add-to-cart,
            .quantity {
                display: none;
            }
        }

    }
    .quantity label { display: none; }
    .add-to-cart { top: 2px; }
    .main-content-group {
        border-top: 1px solid $brand-primary;
        .main-content-item {
            padding: 0;
            margin: 0;
        }
        .card {
            .card-header {
                background: transparent;
            }
            .card-body {
                padding-left: 0;
                padding-right: 0;
            }
            .title {
                background: transparent;
                color: $brand-secondary;
                font-size: 32px;
                text-align: left;
                width: 100%;
                padding-left: 0;
                padding-top: 32px;
                padding-bottom: 32px;
                &:after {
                    top:40px;
                    font-size: 16px;
                }
            }
            &:not(:last-child) {
                border-bottom: 1px solid $brand-primary;
            }
        }
    }
    .bundle-items {
        display: flex;
        flex-wrap: wrap;

        .bundle-item {
            border-top: 0px;

            .primary-images {
                padding: 0px;
            }
        }
    }
}

.non-color-attribute-swatches {
    .non-color-attribute-swatches .swatch-tile-container .swatch {
        line-height: normal;
    }
    .swatch-value,
    .description,
    .name {
        line-height: normal;
    }
}

.product-detail .non-color-attribute-swatches .swatch-tile-container .swatch {
    text-align: left;

    .swatch-value {
        line-height: normal;
        padding: $spacer/4 $spacer;
        font-size: 13px;
        font-weight: 600;
        border: 1px solid $champagne;
        &.selected {
            border: 1px solid $black;
        }
        .description {
            font-size: 14px;
            font-weight: 400;
            color: $brand-primary;
        }
    }
}

.list-recommendations {
    margin-top: 0;
    padding-top: 0;
    border-top: none;

    .alternate-item {
        margin-bottom: $spacer;

        a {
            display: inline-block;
            margin-right: 5px;

            .product-row-tile-image {
                width: 48px;
            }
        }
        &__intro {
            text-transform: uppercase;
            font-weight: bold;
            font-size: $product-tile-price-font-size;
            letter-spacing: normal;
        }
        &__name {
            font-family: $button-font;
            font-size: $button-font-size;
            text-transform: uppercase;
            text-decoration: underline;
            letter-spacing: normal;
            .link {
                color: $brand-primary;
            }
        }
    }
}
.text-link-fragrance-experience {
    font-weight: normal;
    border-bottom: 1px solid $brand-primary;
    display: block;
    &:hover {
        text-decoration: none;
        border-color: $brand-secondary;
    }
}
.product-detail-attributes {
    .product-slider {
        .product-tile {
            margin-bottom: 0;
            .product-tile-image-container:after {
                display:none;
            }
        }
        .buy-options,
        .price {
            display: none;
        }
        .pdp-link .link {
            font-size: 16px;
            letter-spacing: -0.01em;
            text-transform: capitalize;
            color: $dark-gray;
            font-weight: normal;
        }
    }
}

.fake-attribute {
    background-color: $white;
    border: 1px solid $brand-primary;
    color: $brand-primary;
    display: inline-block;
    line-height: normal;
    padding: $spacer/4 $spacer;

    .variant-title {
        font-size: 13px;
        font-weight: 600;
    }

    .variant-subtitle {
        font-size: 14px;
        font-weight: 400;
    }
}

#soldoutModal {
    .modal-dialog {
        max-width: 460px;
    }
    .modal-body {
        padding: 44px 28px 32px;
        text-align: center;
        background-color: $cream;
        p {
            font-size: 16px;
        }
        .small {
            font-size: 12px;
        }
        input.is-invalid + .error-soldout {
            display: block;
        }
        .btn-submit-soldout {
            @include icon($icon-arrow-right, after, 16px, $white);
            background-color: $copper;
            &::after {
                transform: none;
                top: 50%;
                margin-top: -8px;
            }
        }
        .bis_close {
            font-style: italic;
            color: $copper;
            cursor: pointer;
        }
        .group-soldout {
            position: relative;
            .success {
                display: none;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: $cream;
            }
        }
    }
}
