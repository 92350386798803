@import "~core/components/imageTextBlock";

.image-text-block {
    &.shape-arched {
        .image-cropper {
            border-radius: 24em 24em 0 0;
            overflow: hidden;
            position: relative;
            aspect-ratio: 3/4;
            transform: translateZ(0); //safari fix for border-radius on overflow hidden bug
            &:after {
                top:0;
                right: 0;
                bottom: 0;
                left: 0;
                width: auto;
                border:1px solid $cream;
                content: '';
                height: auto;
                margin: 10px;
                border-radius: 24em 24em 0 0;
                position: absolute;
            }
        }
    }
    &.shape-pill {
        .image-cropper {
            border-radius: 24em;
            overflow: hidden;
            position: relative;
            transform: translateZ(0); //safari fix for border-radius on overflow hidden bug
            .image-wrapper {
                height: 100%;
            }
        }
    }
}
