@import "~core/account/account";
.login-page .login-nav-tabs .nav-link {
    font-size: 24px;
    &.active {
        border-color: $brand-secondary;
    }
}

.account-page .card:not(:last-of-type) {
    margin-bottom: $spacer;
    .card-footer {
        border-bottom: 1px solid $light-gray;
    }
}
.account-page {
    .page-title {
        @include header(3);
    }
}
