@import "~core/cart/cart";
.cart-page {
    .checkout-btn {
        &:after {
            @include media-breakpoint-down(xs) {
                content: '';
            }
        }
    }

    .checkout-continue {
        @include media-breakpoint-down(xs) {
            position: relative;
            border: 0!important;
            box-shadow: none;
            width: 100%;
            margin: 0;
        }
    }

    .product-info {
        .remove-btn {
           @include media-breakpoint-down(md) {
                left: auto;
                right: 0;
            }
        }
    }

    .promo-code-label {
        cursor: pointer;
        text-decoration: underline;
    }

    .promo-code-wrapper {
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        transition: max-height $transition-speed ease-in-out,
                    opacity $transition-speed ease-in-out;

        &.show {
            opacity: 1;
            max-height: 150px;
        }
    }

    .remove-item-container {
        flex-direction: column;
        .remove-btn {
            &::before {
                content: none;
            }
            font-size: inherit;
            position: static;
            height: auto;
            width: auto;
            color: $brand-primary;
            text-decoration: underline;
            margin: 20px;
        }

    }

    .single-approaching-discount {
        border: 0;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        .approaching-discount-icon {
            flex-basis: 15%;
            img {
                max-width: 100%;
                min-width: 65px;
            }
        }
        .approaching-discount-text {
            padding-left: 20px;
            font-weight: bold;
            text-align: left;
        }
        @include media-breakpoint-down(md) {
            .approaching-discount-text {
                font-size: 14px;
            }
        }
        @include media-breakpoint-down(sm) {
            .approaching-discount-text {
                font-size: 12px;
            }
        }
    }

    .subtotal-item {
        .subtotal-lines{
            background-color: $card-body-background;
            padding-right: 16px;

            @include media-breakpoint-up(lg) {
                padding-right: $spacer;
            }
            @include media-breakpoint-up(xl) {
                padding-right: $spacer-xl;
            }

            .subtotal-label {
                @include label-styles;
                color: $product-card-attribute-color;
            }

            .subtotal-price {
                font-size: $card-body-font-size;
                letter-spacing: $card-body-letter-spacing;
                line-height: $card-body-line-height;
                color: $card-body-color;
            }
        }
    }

    .cart {
        .card {
            .cart-bundle-items-container {
                padding-left: $spacer;

                @include media-breakpoint-up(md) {
                    padding-left: 100px;
                }

                @include media-breakpoint-up(xl) {
                    padding-left: 175px;
                }

                .bundled-line-item {
                    .bundled-line-item-price {
                        padding-right: $spacer-sm;
                        text-align: right;
                    }

                    &:last-child {
                        border-bottom: 1px solid $card-body-border-color;
                    }
                }
            }
        }
    }
    #cart-gift-recommendations {
        .slider-title {
            font-size: 16px;
            color: $default-brand-primary;
            font-weight: 700;
            font-family: $primary-font;
        }
        .product-name, .pdp-link .link {
            font-weight: normal;
            text-transform: none;
            font-family: $product-card-product-name-font-family;
            font-size: 14px;
        }
        .price {
            font-size: $default-text-font-size;
            font-weight: normal;
        }
    }
}
