@import "~core/components/headerSearch";

.site-search {
    @include media-breakpoint-up(lg) {
        display: flex;
    }
    > form {
        margin-bottom: 0;
    }

    .reset-button {
        padding: 0;
    }

    .search-field {
        padding-right: 52px;
        font-family: $primary-font;
        text-transform: uppercase;
        background-color: none;

        @include media-breakpoint-up(lg) {
            text-align: right;
        }

        &::placeholder {
            color: $dark-gray;
        }
    }
}

// Search suggestions
.suggestions-wrapper {
    .see-all-link-container {
        margin-top: 6px;
        .see-all-link {
            margin-left: 46px;
            text-decoration: none;
        }
    }
}
