@import "~core/product/grid";
[data-filter-type="vertical-drawer"] {
    .grid-header,
    .content-grid-header {
        margin-bottom: $spacer*3;
    }
    .grid-header {
        display: flex;
        align-items: center;

        .btn.filter-results {
            text-align: left;
            border-color: transparent;
            font-weight: 600;
            letter-spacing: 0.1;
            .icon {
                font-size: 18px;
            }
        }
        .result-count {
            font-size: 13px;
        }
        select[name="sort-order"] {
            padding: 4px 16px 4px 0;
            color: $black;
            font-size: 14px;
            font-weight: 700;
            text-transform: uppercase;
            border: none;
            background-position-x: right;
            background-color: transparent;
            direction: rtl;
        }
        .filter-bar {
            margin-top: $spacer*2;

            .filter-value {
                button.btn {
                    font-family: $primary-font;
                    text-transform: none;
                    letter-spacing: 0;
                    @include icon($icon-close-small, after, $product-filter-icon-size);
                }
            }
        }
    }
    .refinement-bar,
    .refinement-bar-close-button {
        position: fixed;
        transform: translateX(-100%);
        transition: transform $transition-speed ease-out;
        left: 0;
        width: 100vw;
        max-width: $product-refinements-drawer-max-width;
        z-index: $z-index-refinement-drawer;

        &.in {
            transform: translateX(0);
        }
    }
    .refinement-bar {
        height: 100vh;
        background-color: $cream;
        overflow-x: hidden;
        overflow-y: auto;
        padding-bottom: $product-refinements-drawer-padding-bottom;
        margin-top: 0;
        top: 0 !important;
        .secondary-bar {
            @include media-breakpoint-up (lg) {
                margin-top: $spacer;
            }
            .secondary-bar-links {
                font-size: 11px;
            }
        }
        a {
            font-weight:normal;
        }
    }
    .refinement-bar-close-button {
        bottom: 0;
        background: $cream;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    }
    .header-bar {
        background: transparent;
        button.close,
        .result-count {
            display: inline-block;
        }
        button.close {
            @include icon($icon-close-small, after, 12px, $product-refinement-header-color);
        }
        .result-count {
            font-weight: 700;
        }
    }
    .refinement-category {
        .values {
            .values {
                margin-left: 0;
            }
        }
    }
}

.refinements {
    .card-header {
        background: transparent;
        .card-title {
            background: transparent;
            span {
                @include header(6);
            }
        }
    }
}

.product-tile .add-to-cart-tile.btn:disabled {
    background-color: transparent;
    color: $gray;
    border-bottom: 1px solid;
}
