// Promo banner
.header-banner {
    background-color: $header-promo-bar-background;
    text-align: center;
    color: $header-promo-bar-color;
    font-size: $header-promo-bar-font-size;
    line-height: $header-promo-bar-line-height;
    position: relative;
    padding-bottom: $header-promo-bar-padding-bottom;
    padding-top: $header-promo-bar-padding-top;
    .header-banner-column-1 {
        .pull-left:not(:empty) {
            margin-left: $spacer;
        }
    }
    .header-banner-column-2 {
        .content {
            width: 100%;
        }
        .close-button {
            .close {
                opacity: 1;
                color: $white;
                font-size: 10px;
                padding: 0 $spacer 0 3px;
            }
        }
    }
    .header-banner-column-3 {
        .pull-right {
            margin-right: $spacer;
        }
    }
    .container {
        padding-left: 0;
        padding-right: 0;
    }
    .dropdown-toggle {
        &:after {
            font-size: $dropdown-icon-size - ($header-promo-bar-font-size - $header-promo-bar-link-font-size);
        }
    }
    .nav-link {
        color: $header-promo-bar-color;
        padding: 0;
    }
}

.pre-nav-header {
    height: $pre-nav-height;
    background-color: $pre-nav-background;
    @include media-breakpoint-up(md) {
        height: $pre-nav-height-md;
    }

    > .container {
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        padding: 0;

        .row{
            width: auto;
            @include media-breakpoint-up(md) {
                width: 100%;
            }
        }
    }

    .site-switcher {
        display: flex;
        flex-flow: row nowrap;

        > a {
            display: flex;
            justify-content: center;
            align-items: center;
            height: $pre-nav-height;
            width: auto;
            @include media-breakpoint-up(md) {
                height: $pre-nav-height-md;
                padding: 0 $spacer;
            }

            img {
                max-height: $pre-nav-height/2;
                width: auto;
                padding: 0 $spacer-sm;
                filter: $pre-nav-logo-filter;
                transition: $transition-speed;
                @include media-breakpoint-up(md) {
                    max-height: $pre-nav-height-md/3;
                    padding: 0;
                }
            }

            &:hover {
                img {
                    filter: $pre-nav-logo-filter-active;
                }
            }

            &.active {
                background-color: $pre-nav-background-active;
                img {
                    filter: $pre-nav-logo-filter-active;
                }
            }
        }

        p {
            margin-bottom: 0;
            color: $pre-nav-text-color;
            font-family: $pre-nav-font-family;
            font-weight: $pre-nav-font-weight;
            font-size: $pre-nav-font-size;
            text-transform: $pre-nav-text-transform;
        }
    }
}

// Fixed header
.header-nav {
    position: relative;
    z-index: $z-index-header;
    background: $header-background;
    border-bottom: $header-navbar-border-bottom-width solid $header-navbar-border-bottom-color;
    &.fixed {
        position: fixed;
        top: 0;
        width: 100%;
        box-shadow: $header-fixed-shadow;
        transition: all $header-transition-speed cubic-bezier(0.77,0,.175,1);
        z-index: $z-index-header-fixed;
        .mobile-menu-in & {
            box-shadow: none;
        }
        .brand .logo-home {
            background: $header-logo-image-sm-fixed no-repeat center;
            background-size: contain;
            @include media-breakpoint-up(md) {
                background: $header-logo-image-md-fixed no-repeat center;
                background-size: contain;
            }
            @include media-breakpoint-up(lg) {
                background: $header-logo-image-lg-fixed no-repeat center;
                background-size: contain;
            }
        }
    }
    &.nav-center {
        @include media-breakpoint-up(lg) {
            .navbar-nav {
                justify-content: center;
                text-align: center;
            }
        }
    }
    .logo-center & {
        @include media-breakpoint-up(lg) {
            border-bottom-width: 0;
        }
    }
}

// enhanced sticky header
.fixed-header-enhanced {
    .sticky-header & {
        &:not(.transparent-header) {
            padding-bottom: var(--header-height);
        }
        .header-nav {
            position: fixed;
            top: 0;
            width: 100%;
            box-shadow: $header-fixed-shadow;
            z-index: $z-index-header-fixed;
            will-change: transform;
            transform: translateY(-100%);
            animation: slide-up $header-transition-speed ease-out forwards;
        }
    }
    .sticky-header.scroll-direction-up & {
        .header-nav {
            animation: slide-down $header-transition-speed ease-out forwards;
        }
    }
}

.header,
.header-banner {
    .store-selector {
        @include media-breakpoint-up(lg) {
            max-width: 200px;
        }
        .store-locator-header-button {
            @include icon($icon-location);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            appearance: none;
            &::before {
                padding-right: 4px;
            }
            &:hover:before {
                color: $header-link-color-hover;
            }
        }
    }
}

// Main header
.header {
    position: relative;
    height: $header-height;
    padding: 0;
    @include media-breakpoint-up(md) {
        height: $header-height-md;
    }
    @include media-breakpoint-up(lg) {
        height: $header-height-lg;
    }
    .row {
        height: 100%;
        align-items: center;
        .col {
            display: flex;
            height: 100%;
            align-items: center;
        }
    }
    & > .row {
        flex-wrap: nowrap;
    }
    .header-column-1 {
        &>.pull-left:not(:empty) {
            margin-left: $spacer;
        }
    }
    .header-column-2 {
        flex-grow: 0;
    }
    .header-column-3 {
        justify-content: flex-end;
        &>.pull-right {
            margin-right: $spacer;
        }
    }
    .nav-link,
    .country-selector {
        color: $header-link-color;
        transition: color $transition-speed ease-out;
        &:hover {
            color: $header-link-color-hover;
            transition: color $transition-speed ease-out;
        }
    }
    .nav-link {
        padding: 5px;
    }
    .brand {
        height: 100%;

        .logo-home {
            width: $header-logo-width;
            height: $header-logo-height;
            margin: 0 auto;
            background: $header-logo-image-sm no-repeat center;
            background-size: contain;
            @include media-breakpoint-up(md) {
                width: $header-logo-width-md;
                background: $header-logo-image-md no-repeat center;
                background-size: contain;
            }
            @include media-breakpoint-up(lg) {
                width: $header-logo-width-lg;
                background: $header-logo-image-lg no-repeat center;
                background-size: contain;
            }
        }
    }
    .navbar-toggler {
        @include icon($icon-menu, before, $header-navbar-toggler-icon-size, $header-link-color);
        padding-left: $spacer;
        padding-right: $spacer;
        &:hover {
            color: $header-link-color-hover;
        }
        .mobile-menu-in & {
            @include icon($icon-close, before, $header-navbar-toggler-icon-size, $header-link-color);
        }
    }
    .minicart {
        .minicart-icon {
            @include icon($icon-cart, before, $header-minicart-icon-font-size, $header-link-color);
            &:hover:before {
                color: $header-link-color-hover;
            }
        }
        .minicart-total {
            position: relative;
            margin-right: 10px;
        }
        .minicart-quantity {
            position: absolute;
            top: -2px;
            right: -9px;
            background-color: $header-minicart-count-background;
            color: $header-minicart-count-color;
            border-radius: 50%;
            width: 16px;
            height: 16px;
            line-height: 16px;
            display: inline-block;
            text-align: center;
            font-size: 9px;
        }
    }
    .custom-dropdown.show {
        .nav-link {
            color: $header-link-color-hover;
        }
    }
}

// Left-aligned logo layout
.logo-left {
    @include media-breakpoint-down(md) {
        .header-column-1 {
            order: 1;
        }
        .header-column-2 {
            order: 2;
        }
        .header-column-3 {
            order: 2;
        }
    }
    @include media-breakpoint-up(lg) {
        .header-banner {
            overflow: visible;
            &-column-3 {
                .dropdown-menu {
                    left: auto;
                    right: 0;
                }
            }
        }
        .main-menu {
            background: transparent;
        }
        .header-column-1 {
            margin-bottom: -1px; // to allign the highlight animated underline
            padding-left: 0;
            padding-right: 0;
            position: static; // needed for megamenu positioning
            overflow-x: auto;
            overflow-y: hidden;
            mask-image: linear-gradient(90deg, rgba($black, 1) 90%, transparent);
            @include hide-scrollbar;
            &:hover,
            &:focus-within {
                mask-image: unset;
            }
        }
        .nav-center {
            .header-column-1 {
                text-align: center;
                .main-menu {
                    margin: 0 auto;
                }
            }
        }
        .header-column-2 {
            padding-left: $spacer;
            padding-right: $spacer;
            @include media-breakpoint-up(lg) {
                min-width: calc(#{$header-logo-width-lg} + (#{$spacer} * 2)); // fix for IE11
            }

            @include media-breakpoint-up(xl) {
                padding-right: $grid-gutter-width;
                min-width: calc(#{$header-logo-width-lg} + #{$spacer} + #{$grid-gutter-width}); // fix for IE11
            }
        }
        .header-column-3 {
            flex-grow: 0;
            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                flex: 1; // fix for IE11
            }
            .pull-right {
                margin-left: $spacer;
                margin-right: $spacer;
                &.search {
                    margin-right: 0;
                }
            }
        }
        .navbar.bg-inverse .navbar-nav {
            >.nav-item {
                &>.nav-link {
                    padding-top: calc((#{$header-height-lg} - #{$header-link-line-height}) / 2);
                    padding-bottom: calc((#{$header-height-lg} - #{$header-link-line-height}) / 2);
                    position: relative;
                    font-size: $header-link-top-level-font-size;

                    &:before {
                        content: '';
                        display: block;
                        height: $header-link-underline-height;
                        background-color: $header-link-underline-color;
                        position: absolute;
                        bottom: $header-link-underline-position-bottom;
                        transition: right $transition-speed ease-out;
                        left: $header-navbar-link-spacing/2;
                        right: 100%;
                        z-index: 1001;
                    }
                }

                &>.nav-link:hover:before,
                &.show .nav-link:before {
                    right: $header-navbar-link-spacing/2;
                }

                &.highlight {
                    .nav-link {
                        color: $header-link-highlight-color;
                    }
                }
            }
            &.nav-item-hover {
                > .nav-item:not(:hover) {
                    .nav-link {
                        color: $dark-gray;
                        transition: color $transition-speed ease-out;
                    }
                }
                > .nav-item.highlight {
                    .nav-link:hover {
                        color: $header-link-highlight-color-hover;
                    }
                }
            }
            &:not(.nav-item-hover) {
                > .nav-item {
                    &.is-inview,
                    &.contains-inview {
                        > .nav-link:before {
                            right: $header-navbar-link-spacing/2;
                            height: $header-link-underline-height-inview;
                            background-color: $header-link-underline-color-inview;
                        }
                    }
                }
            }
        }
    }
}

// Header nav menu
.main-menu {
    background-color: $header-navbar-background;
    .navbar {
        .close-menu button,
        .close-button button {
            background-color: transparent;
            border: 0;
            -webkit-appearance: none;
        }
        .close-button button {
            @include icon($icon-close, after, 12px);
            span {
                display: none;
            }
        }
        @include media-breakpoint-up(lg) {
            .nav-item.custom-dropdown {
                &>.dropdown-toggle {
                    @include icon($header-navbar-dropdown-icon, after, $header-navbar-dropdown-icon-size, inherit);
                }
            }
        }
    }
    .dropdown-link,
    .nav-link {
        @include navigation-link;
    }
}

.slide-up {
    transition-duration: 0.5s;
    transition-timing-function: ease-in;
    max-height: 100px;
    overflow: hidden;

    &.hide {
        max-height: 0;
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
        transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    }
}

.cookie-warning-messaging.cookie-warning {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;
    display: none;
}

.valid-cookie-warning {
    background-color: $blue;
    color: $white;
    white-space: nowrap;

    p {
        margin-top: 0;
        margin-bottom: 0.2em;
        padding-right: 2em;
    }
}

// Component visibility settings for checkout version of header
.checkout-header {
    .header-locale-container .country-selector {
        display: $header-checkout-locale-display;
    }
    .header-support-container .support {
        display: $header-checkout-support-display;
    }
    .header-account-container {
        display: $header-checkout-account-display;
    }
    .minicart {
        display: $header-checkout-minicart-display;
    }
}

// Account
.header-account-container {
    display: none;
    @include media-breakpoint-up(lg) {
        display: block;
        .header-link.nav-link {
            font-size: $header-navbar-account-font-size;
            margin: $header-navbar-account-margin;
            @include icon($header-navbar-account-icon, before, $header-navbar-account-icon-size, $header-link-color);

            > .header-link {
                display: none;
            }
        }
    }
}
@include media-breakpoint-up(lg) {
    header {
        .user,
        .support {
            .dropdown-menu {
                border: $header-link-dropdown-menu-border;
                box-shadow: $header-link-dropdown-menu-box-shadow;
                .dropdown-item {
                    + .dropdown-item {
                        border: none;
                    }

                    .header-link {
                        text-align: $header-link-dropdown-text-align;
                        padding: $header-link-dropdown-menu-li-padding;
                    }
                }
            }

            .nav {
                padding: $header-link-dropdown-menu-padding;
                display: block;
            }
        }

        &.logo-center {
            .header-link {
                font-size: $header-link-font-size-logo-center;
            }

            .country-selector,
            .support {
                .dropdown-toggle {
                    padding: calc((#{$header-height-lg} - #{$header-link-line-height}) / 2) 0;
                }
                .dropdown-menu-right {
                    right: auto;
                    max-width: 225px; // fix for IE11
                }
            }
            .header-column-2 {
                min-width: $header-logo-width-lg; // fix for IE11
            }
        }
    }
}

//transparent nav
html:not(.sticky-header) {
    .transparent-header {
        .header-nav {
            width: 100%;
            transition: background-color $transition-speed linear, border-color $transition-speed linear;

            @include media-breakpoint-up(md) {
                &.nav-center .main-menu {
                    transition: background-color $transition-speed linear;
                }
                .brand a {
                    transition: filter $transition-speed linear;
                }
                .nav-link,
                .nav-link::before,
                .fa-search::before,
                .site-search input,
                .site-search input::placeholder,
                .minicart-icon::before,
                .store-locator-header-button {
                    transition: color $transition-speed linear;
                }
                .site-search {
                    transition: border-color $transition-speed linear;
                    .search-field {
                        transition: width $transition-speed linear;
                    }
                }
                .minicart-quantity {
                    transition: color $transition-speed linear, background-color $transition-speed linear;
                }
            }

            &:not(.fixed) {
                position: absolute;
            }

            &.fixed {
                .header {
                    background-color: $header-transparent-background;
                }
            }

            &.transparent-nav {
                transition: color $transition-speed ease-in-out, box-shadow $transition-speed ease-in-out;
                &.transparent-nav-dark {
                    color: $header-transparent-text-color-dark;
                    &:not(.fixed) {
                        .navbar-toggler {
                            @include icon($icon-menu, before, $header-navbar-toggler-icon-size,  $header-transparent-text-color-dark);
                        }

                        .brand a {
                            filter: brightness($header-logo-transparent-nav-brightness);
                        }

                        .minicart {
                            .minicart-icon::before {
                                @include icon($icon-cart, before, $header-minicart-icon-font-size, color);
                                color: $header-transparent-text-color-dark;
                            }

                            .minicart-quantity {
                                color: $header-transparent-text-color-light;
                                background-color: $header-transparent-minicart-count-background-color-dark;
                            }
                        }

                        .store-selector .store-locator-header-button {
                            color: $header-transparent-text-color-dark;
                        }

                        .nav-item > .nav-link,
                        .nav-item > .nav-link::before,
                        .user > .nav-link::before,
                        .user > .nav-link::after,
                        .support > .nav-link,
                        .support > .nav-link::after,
                        .country-selector > .dropdown-toggle::after,
                        .fa-search::before,
                        .site-search input {
                            @include media-breakpoint-up(md) {
                                color: $header-transparent-text-color-dark;
                            }
                        }

                        .site-search {
                            .search-field::placeholder {
                                @include media-breakpoint-up(md) {
                                    color: $header-transparent-text-color-dark;
                                }
                            }
                        }

                        .navbar .menu-group .nav.navbar-nav {
                            > .nav-item:not(:hover) {
                                > .nav-link {
                                    @include media-breakpoint-up(md) {
                                        color: $header-transparent-text-color-dark;
                                        &::before {
                                            background-color: $header-transparent-text-color-dark;
                                        }
                                    }
                                }
                            }

                            > .nav-item:hover {
                                > .nav-link {
                                    @include media-breakpoint-up(md) {
                                        color: $header-transparent-text-color-dark;
                                    }
                                }
                            }
                        }
                    }
                }
                &.transparent-nav-light {
                    color: $header-transparent-text-color-light;
                    &:not(.fixed) {
                        .navbar-toggler {
                            @include icon($icon-menu, before, $header-navbar-toggler-icon-size,  $header-transparent-text-color-light);
                        }

                        .brand a {
                            filter: brightness($header-logo-transparent-nav-brightness) invert($header-logo-transparent-nav-invert);
                        }

                        .minicart {
                            .minicart-icon::before {
                                @include icon($icon-cart, before, $header-minicart-icon-font-size, color);
                                color: $header-transparent-text-color-light;
                            }

                            .minicart-quantity {
                                color: $header-transparent-text-color-light;
                                background-color: $header-transparent-minicart-count-background-color-light;
                            }
                        }

                        .store-selector .store-locator-header-button {
                            color: $header-transparent-text-color-light;
                        }

                        .nav-item > .nav-link,
                        .nav-item > .nav-link::before,
                        .user > .nav-link::before,
                        .user > .nav-link::after,
                        .support > .nav-link,
                        .support > .nav-link::after,
                        .country-selector > .dropdown-toggle::after,
                        .fa-search::before,
                        .site-search input {
                            @include media-breakpoint-up(md) {
                                color: $header-transparent-text-color-light;
                            }
                        }

                        .site-search {
                            .search-field::placeholder {
                                @include media-breakpoint-up(md) {
                                    color: $header-transparent-text-color-light;
                                }
                            }
                        }

                        .navbar .menu-group .nav.navbar-nav {
                            > .nav-item:not(:hover) {
                                > .nav-link {
                                    @include media-breakpoint-up(md) {
                                        color: $header-transparent-text-color-light;
                                        &::before {
                                            background-color: $header-transparent-text-color-light;
                                        }
                                    }
                                }
                            }

                            > .nav-item:hover {
                                > .nav-link {
                                    @include media-breakpoint-up(md) {
                                        color: $header-transparent-text-color-light;
                                    }
                                }
                            }
                        }
                    }
                }

                &:not(.fixed) {
                    background: $header-transparent-background;
                    border-bottom: $header-transparent-border-bottom;
                    transition: background-color $transition-speed linear, border-color $transition-speed linear;
                    width: 100%;
                    position: absolute;

                    .main-menu {
                        @include media-breakpoint-up(md) {
                            background-color: $header-transparent-background;
                            transition: background-color $transition-speed linear;
                        }
                    }

                    .header {
                        transition: color $transition-speed linear;
                        background: $header-transparent-background;

                        .navbar .menu-group .nav.navbar-nav {
                            > .nav-item:not(:hover) {
                                .nav-link {
                                    @include media-breakpoint-up(md) {
                                        transition: color $transition-speed ease-out;
                                    }
                                }
                            }
                        }

                        .site-search {
                            border-color: $header-transparent-search-desktop-expanded-border-color;
                        }
                    }
                }
            }
        }
    }
}
