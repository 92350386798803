@import "~core/components/breadcrumb";

.breadcrumb {
    .breadcrumb-item {
        a {
            letter-spacing: 0.02em;
            font-size: 12px;
        }
        &:not(:last-child) {
            a {
                font-weight: 600;
            }
        }
    }
}
