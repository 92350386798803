@import "~core/components/formFields";
.row {
    .form-group {
        margin: 0;
        &~.form-group {
            margin-top: $form-group-margin-top;
        }
    }
}

.form-control, select.form-control{
    &:disabled,
    &.disabled {
        color: $input-disabled-color;
        background-color: $input-disabled-background-color;
        border-color: $input-disabled-border-color;
    }
}

.custom-radio,
.custom-checkbox {
    .custom-control-input {
        &:disabled ~ .custom-control-label {
            opacity: 1;
        }
    }
}
.inline-btn {
    input {
        padding-left: $input-padding-left;
    }
    .btn {
        padding-right:  $input-padding-right;
    }
}
@import "floating-labels";