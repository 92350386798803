@import "~core/components/footer";
@import "collapsibleItem";

.email-signup-form {
    background-color: $cream;
    margin-bottom: $footer-padding-top;
    .email-description {
        margin-top: $spacer-xl;
        padding-top: 150px;
        background: url('../images/footer_leaf.jpg') no-repeat top center;
        background-size: 210px;
    }
    .btn {
        @include icon($icon-arrow-right-thin, after, 22px, $brand-primary);
        &::after {
            margin-top: 5px;
            transform: none;
        }
    }
}

footer {
    padding-top: 0;
    .content {
        font-size: $default-text-font-size;
        li {
            line-height: 36px;
        }
    }
    hr {
        border-color: $champagne;
    }
    .footer-copyright {
        @include media-breakpoint-up(lg) {
            padding-top: 32px;
        }
    }
    .title {
        font-family: $secondary-font;
        text-transform: unset;
        font-weight: 400;

    }
    .copyright {
        font-size:12px;
        letter-spacing: 0.05em;
    }
    .footer-top {
        .back-to-top {
            position: fixed;
            bottom: 16px;
            z-index: $z-index-sticky-footer;
            top: auto;
            right: $spacer/2;

            @include media-breakpoint-up(md) {
                right: $spacer;
            }

            .fa-stack {
                width: $footer-icon-size*2;
                height: $footer-icon-size*2;
                line-height: $footer-icon-size*2;
            }
        }

        .back-to-top-extra-height {
            bottom: 65px;
        }
    }
}
.footer-item {
    &.collapsible-md {
        .title {
            background-color: transparent;

            padding-bottom: $spacer;
            @include media-breakpoint-down(md) {
                padding: $spacer;
                border-bottom: 1px solid $champagne;
            }
            margin-bottom:0;
            &[aria-expanded="true"] {
                border-bottom: none;
            }
            &:after {
                font-size: $collapsible-header-icon-size;
            }
        }
    }
    .menu-footer {
        padding-top: 0;

        padding-bottom: $spacer;
        @include media-breakpoint-down(md) {
            border: 1px solid $champagne;
            padding-left: $spacer * 2;
        }

        a {
            letter-spacing: 0.05em;
        }
    }
}
