@import "~core/components/productTiles";

//Vertical Grid Alignment
.product {
    display: flex;
    height: 100%;

    .product-tile:hover .product-tile-image-container:after {
        display: none;
    }
    .product-tile {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        width: 100%;

        .tile-body {
            display: flex;
            flex-flow: column nowrap;
            flex-grow: 1;
            justify-content: space-between;

            &.align-items-center {
                align-items: unset !important; //Added to remove the align-items that was introduced when centering tile content because it was wrapping CTA text.
            }

            .title,
            .price {
                text-align: center;
            }
        }
    }
}
.product-grid {
    @include media-breakpoint-up(sm) {
        .col-12:not(:nth-child(4n+4)) {
            .product {
                .product-tile {
                    border-right: 1px solid $champagne;
                }
            }
        }
    }
}

.product-tile {
    .quickview {
        position: relative;
        bottom: auto;
        right: auto;

        &.btn {
            width: initial;
            opacity: 1;
        }
    }
    .pdp-link {
        .link {
            font-size: $product-tile-title-font-size;
            @include media-breakpoint-up(md) {
                font-size: $product-tile-title-font-size-lg;
            }
        }
    }
    .price {
        font-size: $product-tile-price-font-size;
        text-transform: $product-tile-title-text-transform;
        @include media-breakpoint-up(lg) {
            font-size: $product-tile-price-font-size-lg;
        }

        > span:not(.range) {
            display: flex;
            justify-content: center;

            del {
                order: 1;
            }
        }

        .range.promo {
            color: $product-tile-sale-color;
        }
    }
    .pdp-fragrance {
        .link {
            font-size: 12px;
            font-style: italic;
            font-family: $primary-font;
            color: $brand-primary;
            font-weight: 400;
            letter-spacing: 1px;
            &:hover {
                text-decoration: none;
            }
        }
    }
    .buy-options {
        width: 100%;
        margin-top: $spacer/2;

        .available-variants,
        .quick-actions {
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
            width: calc(70% - #{$spacer/2});
            @include media-breakpoint-up(md) {
                width: calc(50% - #{$spacer/2});
            }
        }
        .available-variants-ratings {
            .ratings {
                display: flex;
                align-items: center;
                justify-content: center;
                > * {
                    font-size: 12px;
                    font-style: italic;
                }
            }
        }
        .quick-actions {
            margin: 0 auto;

            .btn-sm {
                padding: 5px 8px;
                font-size: 12px;
            }
        }
    }
    .show-secondary-image {
        .product-tile-image {
            display: inline-block;
        }

        .product-tile-image-secondary {
            display: none;
        }
    }
}

.product-tile:hover .product-tile-image-container:after {
    display: none;
}

.experience-commerce_assets-productTile {
    .product-tile-image-link {
		display: inline-block;
	}

    .tile-body {
        .pdp-link {
            a {
                font-size: $product-tile-title-font-size;
                @include media-breakpoint-up(md) {
                    font-size: $product-tile-title-font-size-lg;
                }
            }
        }
        .price {
            font-size: 16px;
        }
    }
}
.product-slider-slot {
    .slide {
        padding-right: 0 !important;

        .product {
            .product-tile {
                    border-right: 1px solid $champagne;
            }
        }
    }
}

.product-detail-attributes {
    .slide {
        padding-right: 0 !important;

        .product {
            .product-tile {
                    border: none;
            }
        }
    }
}