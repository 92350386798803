
@import "~core/components/miniCart";
.minicart {
    .minicart-item {
        .line-item-availability,
        .item-attributes {
            width: 100%;
            .line-item-attributes {
                display: none;
            }
        }
        .line-item-name {
            white-space: normal;
            overflow: visible;
            text-overflow: unset;
        }
        .bundled-line-item {
            .bundled-line-item-price {
                padding-right: 15px;
                text-align: right;
            }

            .item-image {
                padding-left: 12px;
                padding-right: 4px;
                width: initial;

                .product-image {
                    max-width: initial;
                }
            }

            &+.bundled-line-item {
                margin-top: $spacer - $spacer/2;
                padding-top: $spacer;
            }

        }
    }
    .estimated-total {
        .sub-total-label,
        .sub-total {
            font-size: 16px;
        }
    }
    .item-price-qty {
        padding-top: 0;
    }
    .remove-btn:before {
        font-size: 8px !important;
    }
    .item-count {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    .single-approaching-discount {
        border: 0;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        .approaching-discount-icon {
            flex-basis: 15%;
            img {
                max-width: 100%;
                min-width: 65px;
            }
        }
        .approaching-discount-text {
            padding-left: 20px;
            font-weight: bold;
            text-align: left;
        }
    }
}
.minicart {
    .popover {
        min-width: $minicart-size-x;
        max-width: $minicart-size-x;
        min-height: auto;
        @include media-breakpoint-down(sm) {
            min-width: 300px;

            .minicart-footer {
                padding-bottom: 10px;
            }
        }
    }
    .minicart-label {
        @include header(5);
    }
    .remove-btn {
        left: unset;
        right: 0;
    }
    .product-summary {
        .line-item-name {
            width: 100%;
            @include header(6);
        }
    }

    .product-line-item {
        position: relative;
        .line-item-unit-price,
        .line-item-total-price-label {
            display: none;
        }
        .price {
            font-size: 16px;
        }
    }
    .item-price-qty {
        .col {
            flex-direction: column;
            align-items: flex-start;
        }
    }
}
